import { IBusinessCard } from '../models/business-card.model';
import { PHONE_NUMBER } from './consts';

export const keinoBusinessCard: IBusinessCard = {
    groupMember: 'keino',
    translations: {
        pl: {
            name: 'Keino Grupa Sp. z o. o.',
            nip: '701 045 6633',
            email: 'biuro@keinogrupa.pl',
        },
    },
};

export const midoBusinessCard: IBusinessCard = {
    groupMember: 'mido',
    translations: {
        pl: {
            name: 'Mido Sp. z o. o.',
            nip: '701 060 7724',
            email: 'kontakt@mido.pl',
        },
    },
};

export const johoBusinessCard: IBusinessCard = {
    groupMember: 'joho',
    translations: {
        pl: {
            name: 'Joho Sp. z o. o.',
            nip: '525 276 2634',
            email: 'kontakt@joho.pl',
            street: 'Żaryna',
            streetNumber: '2b (bud. C, 3 p.)',
            zipCode: '02-593',
            city: 'Warszawa',
            phone: PHONE_NUMBER,
            phoneLabel: 'tel.: 22 428 25 59',
        },
    },
};

export const businessCards: IBusinessCard[] = [
    johoBusinessCard,
];
